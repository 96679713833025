import {datadogRum} from '@datadog/browser-rum'
import React, {useEffect, createContext} from 'react'

import config from 'config'
import {useToken} from './token-provider'

// Initializes Datadog's static interface.
// Datadog tokens meant for browser-side usage. Not sensitive.
datadogRum.init({
    env: config.datadog.env,
    service: 'Turbocharger',
    allowedTracingUrls: [/^https?:\/\/api\.*\.pleo\.io\//],
    site: 'datadoghq.eu',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    traceSampleRate: 100,
    // Never enable trackInteractions! It can leak sensitive data.
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    applicationId: '88215bb9-51ac-42c4-8ab2-6bd4e3de01d3',
    clientToken: 'pub0860b0e71d8f1b5ada0fae0bd3601cb7',
    startSessionReplayRecordingManually: true,
    defaultPrivacyLevel: 'mask',
    useCrossSiteSessionCookie: true,
    version: 'v1.0.0'
})

export const UserContext = createContext<{userId: string | undefined}>({userId: undefined})

function setUser(email: string | undefined) {
    datadogRum.onReady(() => {
        datadogRum.setUser({
            email
        })
    })
}

function clearUser() {
    datadogRum.onReady(() => {
        datadogRum.clearUser()
    })
}

export const DatadogRUMProvider: React.FC<
    React.PropsWithChildren<{
        children: React.ReactChild
    }>
> = ({children}) => {
    const token = useToken()

    const datadogContextValue = {
        userId: undefined,
        userEmail: 'test+turbocharger@pleo.dev' || undefined
    }

    useEffect(() => {
        if (token) {
            datadogRum.startSessionReplayRecording()
        } else {
            datadogRum.stopSessionReplayRecording()
        }
    }, [token])

    useEffect(() => {
        setUser(datadogContextValue.userEmail)
        return clearUser
    }, [datadogContextValue.userEmail])

    return <UserContext.Provider value={datadogContextValue}>{children}</UserContext.Provider>
}
